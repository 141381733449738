import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
padding: 20px 0px;
font-family: "Quicksand", sans-serif;
  font-weight: 500;
  color: #424949;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  position: relative;
h1 {
  height: 38px;
  margin: 60px 0;
  font-size: 40px;
}
h1 span {
  white-space: nowrap;
}

h3 {
  font-size: 24px;
  margin-bottom: 12px;
}

p {
  font-size: 16px;
  line-height: 19px;
}

.flex-trombi {
  margin-top: 3vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-child {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 20vw;
}

.flex-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10vh;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 80vw;
  max-width: 1000px;
  position: relative;
  z-index: 0;
  margin-left: calc((80vw - 25px) / 20);
}

input {
  width: 25px;
  height: 25px;
  background-color: #2C3E50;
  position: relative;
  border-radius: 50%;
  display: block;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}
input:focus {
  outline: none;
}
input::before, input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #2C3E50;
  width: 4vw;
  height: 5px;
  max-width: 50px;
}
input::before {
  left: calc(-4vw + 12.5px);
}
input::after {
  right: calc(-4vw + 12.5px);
}
input:checked {
  background-color: #2C3E50;
}
input:checked::before {
  background-color: #2C3E50;
}
input:checked::after {
  background-color: #AEB6BF;
}
input:checked ~ input, input:checked ~ input::before, input:checked ~ input::after {
  background-color: #AEB6BF;
}
input:checked + .dot-info span {
  font-size: 13px;
  font-weight: bold;
}

.dot-info {
  width: 25px;
  height: 25px;
  display: block;
  visibility: hidden;
  position: relative;
  z-index: -1;
  left: calc((((80vw - 25px) / 20) * -1) - 1px);
}
.dot-info span {
  visibility: visible;
  position: absolute;
  font-size: 12px;
}
.dot-info span.year {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.dot-info span.label {
  line-height: 14px;
  top: -65px;
  left: 0;
  transform: rotateZ(-45deg);
  width: 70px;
  text-indent: -10px;
}

#timeline-descriptions-wrapper {
  width: 100%;
  margin-top: 140px;
  font-size: 22px;
  font-weight: 400;
  margin-left: calc((-80vw - 25px) / 20);
}
#timeline-descriptions-wrapper p {
  margin-top: 0;
  display: none;
}

input [data-description="1910"] :checked ~ #timeline-descriptions-wrapper p[data-description="1910"] {
  display: block;
}

input [data-description="1920"] :checked ~ #timeline-descriptions-wrapper p[data-description="1920"] {
  display: block;
}

input [data-description="1930"] :checked ~ #timeline-descriptions-wrapper p[data-description="1930"] {
  display: block;
}

input [data-description="1940"] :checked ~ #timeline-descriptions-wrapper p[data-description="1940"] {
  display: block;
}

input [data-description="1950"] :checked ~ #timeline-descriptions-wrapper p[data-description="1950"] {
  display: block;
}

input [data-description="1960"] :checked ~ #timeline-descriptions-wrapper p[data-description="1960"] {
  display: block;
}

input [data-description="1970"] :checked ~ #timeline-descriptions-wrapper p[data-description="1970"] {
  display: block;
}

input [data-description="1980"] :checked ~ #timeline-descriptions-wrapper p[data-description="1980"] {
  display: block;
}

input [data-description="1990"] :checked ~ #timeline-descriptions-wrapper p[data-description="1990"] {
  display: block;
}

input [data-description="2000"] :checked ~ #timeline-descriptions-wrapper p[data-description="2000"] {
  display: block;
}

@media (min-width: 1250px) {
  .input-flex-container {
    margin-left: 62.5px;
  }

  input::before {
    left: -37.5px;
  }
  input::after {
    right: -37.5px;
  }

  .dot-info {
    left: calc((((1000px - 25px) / 20) * -1) - 1px);
  }

  #timeline-descriptions-wrapper {
    margin-left: -37.5px;
  }
}
@media (max-width: 630px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 400px;
    height: auto;
    margin-top: 15vh;
    margin-left: 0;
    padding-bottom: 30px;
  }

  input, .dot-info {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
  }

  input {
    background-color: transparent !important;
    z-index: 1;
  }
  input::before, input::after {
    content: none;
  }
  input:checked + .dot-info {
    background-color: #2C3E50;
  }
  input:checked + .dot-info span.year {
    font-size: 14px;
  }
  input:checked + .dot-info span.label {
    font-size: 12px;
  }

  .dot-info {
    visibility: visible;
    border-radius: 50%;
    z-index: 0;
    left: 0;
    margin-left: -70px;
    background-color: #AEB6BF;
  }
  .dot-info span.year {
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ECF0F1;
  }
  .dot-info span.label {
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }

  #timeline-descriptions-wrapper {
    margin-top: 30px;
    margin-left: 0;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .input-flex-container {
    width: 340px;
  }
}
@media (max-width: 400px) {
  .input-flex-container {
    width: 300px;
  }
}
`


const Home = () => {

  return (
    <Container>
      <div style={{ margin: '80px', height: '100%' }}>
        <h1>L'équipe</h1>
        <div class="flex-trombi">
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/cdv.png" width="200" />
              </div>
              <div class="flex-child">
                <h3>Clovis de Villars</h3>
                <p>Développeur/Architecte logiciel, Intégrateur</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/js.jpeg" width="200" />
              </div>
              <div class="flex-child">
                <h3>Jessy Sobreiro</h3>
                <p>Chef de projet, Développeur polyvalent, Veille technologique, Relations
                  publiques</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/pdf.jpeg" width="200" />
              </div>
              <div class="flex-child">
                <h3>Pedro da Fonseca</h3>
                <p>Développeur polyvalent, Intégrateur</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/mp.jpeg" width="200" />
              </div>
              <div class="flex-child">
                <h3>Mathis Pinsault</h3>
                <p>Développeur/Architecte logiciel, Intégrateur</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/nb.jpeg" width="200" />
              </div>
              <div class="flex-child">
                <h3>Nathan Bonnot</h3>
                <p>Développeur polyvalent, Veille technologique, Relations publiques</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/sz.jpeg" width="200" height="200" />
              </div>
              <div class="flex-child">
                <h3>Valentin Szabo</h3>
                <p>UI/UX Designer, Développeur, Quality Assessment (user testing)</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div class="flex-child">
                <img style={{ borderRadius: "50%" }} alt="" src="/images/hv.jpeg" width="200" />
              </div>
              <div class="flex-child">
                <h3>Hervé Vaterlaus</h3>
                <p>Développeur (portage sur différents navigateurs), Quality Assessment &
                  Tests</p>
              </div>
            </div>
          </div>
        </div>
        <h1>Timeline</h1>
        <div class="flex-parent">
          <div class="input-flex-container">
            <input type="radio" name="timeline-dot" data-description="09/2019" />
            <div class="dot-info" data-description="09/2019">
              <span class="year">09/2019</span>
              <span class="label">Naissance de D4Data</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="11/2019" />
            <div class="dot-info" data-description="11/2019">
              <span class="year">11/2019</span>
              <span class="label">Premier prototype fonctionnel</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="04/2020" />
            <div class="dot-info" data-description="04/2020">
              <span class="year">04/2020</span>
              <span class="label">Maturation du projet</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="07/2020" />
            <div class="dot-info" data-description="07/2020">
              <span class="year">07/2020</span>
              <span class="label">Consolidation des fonctionnalités</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="11/2020" />
            <div class="dot-info" data-description="11/2020">
              <span class="year">11/2020</span>
              <span class="label">Refonte du design</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="04/2021" />
            <div class="dot-info" data-description="04/2021">
              <span class="year">04/2021</span>
              <span class="label">Ouverture d'une bêta restreinte</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="06/2021" />
            <div class="dot-info" data-description="06/2021">
              <span class="year">06/2021</span>
              <span class="label">Ouverture de la bêta</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="08/2021" />
            <div class="dot-info" data-description="08/2021">
              <span class="year">08/2021</span>
              <span class="label">Apport de correctifs</span>
            </div>
            <input type="radio" name="timeline-dot" data-description="12/2021" />
            <div class="dot-info" data-description="12/2021">
              <span class="year">12/2021</span>
              <span class="label">Release finale</span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Home;