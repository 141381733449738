import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const FormContainer = styled.form`
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: #ffffff;
  padding-left: 10px;
  max-width: 75%;
  input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
`;

const MailInput = styled.input`
    color: black;
    border: none;
    font-size: 14px;
    outline: none; 
  &::placeholder {
  }
`;

const ValidateInput = styled.input`
  background: #333333;
  &:hover {
    background: #000000;
  }
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  border: none;
`;


const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan invert-color">
            <h3 className="m-0" style={{ color: "#000000" }}>
              Inscrivez-vous à la newsletter
            </h3>
          </div>
          <div className="cta-action">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FormContainer target="_blank" action="https://gmail.us4.list-manage.com/subscribe/post" method="POST">
                <input type="hidden" name="u" value="8da92cad2275d0c65899f9d28" />
                <input type="hidden" name="id" value="fd9527f34e" />
                <MailInput type="email" name="MERGE0" id="MERGE0" label="Subscribe" labelHidden hasIcon="right" placeholder="Adresse éléctronique" />
                <ValidateInput type="submit" value="valider" />
              </FormContainer>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
