import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';

import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import LayoutDefault from './layouts/LayoutDefault';
import AboutComponent from './views/AboutComponent';

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/about" component={AboutComponent} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;