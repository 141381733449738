import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import styled from 'styled-components';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const SplitItemContainer = styled.div`
  display: flex;
  gap: 100px;
  flex-direction: column;
`;

const SplitItem = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    gap: 20px;
flex-direction: column;
align-items: center;
justify-content: center;
  }
`

const Item = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ $right }) => $right ? "flex-end" : "flex-start"};
  h3 {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const Divider = styled.hr`
    width: 100%;
    height: 1px;
    color: #e0e0e0;
  `;

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SplitItemContainer>
            <SplitItem>
              <Item>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Visualiser ses données
                </div>
                <h3 className="mt-0 mb-12">
                  Application
                </h3>
                <p className="m-0">
                  Une fois vos données récupérées, la partie n'est pas gagnée. Grâce à l'application D4Data, consultez facilement les données personnelles détenues par les sites supportés.
                </p>
              </Item>
              <Item $right>
                <Image
                  src={require('./../../assets/images/application-image.png')}
                  alt="Features split 01"
                  width={300}
                />
              </Item>
            </SplitItem>
            <Divider />
            <SplitItem>
              <Item>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Récupérer ses données
                </div>
                <h3 className="mt-0 mb-12">
                  Extension Chrome (à venir)
                </h3>
                <p className="m-0">
                  Récupérer ses données peut s'avérer long et laborieux. Grâce à l'extension de navigateur pour Chrome, récupérez simplement vos données personnelles sur les sites supportés.
                </p>
              </Item>
              <Item $right>
                <Image
                  src={require('./../../assets/images/extension-image.png')}
                  alt="Features split 01"
                  width={300}
                />
              </Item>            </SplitItem>
          </SplitItemContainer>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;