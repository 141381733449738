import React, { useCallback } from 'react';
import styled from 'styled-components';

const Nav = styled.div`
display: flex;
gap: 20px;
span {
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
  color: #e5e5e5;
  :hover {
    opacity: 1;
    color: #33d19f;
  }
}
  
`;

const FooterNav = () => {


  const onClickAbout = useCallback(() => {
    window.location.replace("/about");
  }, [])

  const onContact = useCallback(() => {
    window.open("mailto:d4data_2022@labeip.epitech.eu", "_blank");
  }, [])

  const onClickCookies = useCallback(() => {
    window.orejime.show()
  }, [])


  return (
    <Nav>
      <span onClick={onContact}>Contact (courriel)</span>
      <span onClick={onClickAbout}>À propos</span>
      <span onClick={onClickCookies}>Gérer mes cookies</span>
    </Nav>
  );
}

export default FooterNav;