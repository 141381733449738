import React, { useCallback } from 'react';
import styled from 'styled-components';

const Container = styled.div`
display: flex;
gap: 10px;
  align-items: center;
  `;

const SVGUI = styled.div`
svg {
  fill: #33d19f;
  height: 30px;
  opacity: .5;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 1;
  }
}

`;

const FooterSocial = ({
  className,
  ...props
}) => {


  const onClick = useCallback((url) => () => {
    window.open(url, '_blank');
  }, [])

  return (
    <Container
    >
      <SVGUI onClick={onClick("https://twitter.com/d4data_official")}>
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg">
          <title>Twitter</title>
          <path
            d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
        </svg>
      </SVGUI>
      <SVGUI href="https://www.instagram.com/d4data_" onClick={onClick("https://www.instagram.com/d4data_")}>
        <svg
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg">
          <title>Instagram</title>
          <g>
            <circle cx="12.145" cy="3.892" r="1" />
            <path
              d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
            <path
              d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
          </g>
        </svg>
      </SVGUI>
      <SVGUI onClick={onClick("https://github.com/d4data-official")}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
      </SVGUI>
    </Container >
  );
}

export default FooterSocial;