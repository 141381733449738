/* eslint-disable no-useless-escape */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { osName } from "react-device-detect"
import { ReactComponent as WindowsLogo } from "../../assets/images/windows-logo.svg"
import GithubLogo from "../../assets/images/github-logo.svg"
import { ReactComponent as AppleLogo } from "../../assets/images/apple-logo.svg"
import { ReactComponent as LinuxLogo } from "../../assets/images/linux-logo.svg"
import { ReactComponent as ArrowUpLogo } from "../../assets/images/arrow-up.svg"
import { SectionProps } from '../../utils/SectionProps';
import styled from 'styled-components';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}


const basics = {
  macos: {
    osname: 'Mac OS',
    logo: AppleLogo,
  },
  windows: {
    osname: 'Windows',
    logo: WindowsLogo,
  },
  linux: {
    osname: 'Linux',
    logo: LinuxLogo,
  },
}

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  justify-content: center;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

const SourceButtonUI = styled.button`
padding: 0 16px;
width: 100%;
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
  gap: 10px;
  span {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
  }
  svg {
    height: 31px;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const DownloadButtonContainerUI = styled.div`
width: 100%;
  display: flex;
  gap: 1px;
  height: 55px;
  align-items: center;
`

const DownloadButtonUI = styled.button`
  display: flex;
justify-content: space-between;
  width: ${({ $secondary }) => $secondary ? "100%" : "90%"};
  align-items: center;
  height: 55px;
  color: #ffffff;
  background-color: #333333;
  :hover {
    background-color: #000000;
  }
  border: none;
  gap: 10px;

  padding: 0px 16px;
  padding-right: ${({ $secondary }) => $secondary ? "calc(10% + 16px)" : "16px"};
  span {
    width: 80%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
  }
  svg {
    fill: #ffffff;
    height: 31px;
  }
`;

const ExpendButtonUI = styled.button`
align-items: center;
justify-content: center;
border: none;
background-color: #333333;
  :hover {
    background-color: #000000;
  }
  display: flex;
  height: 55px;
  width: 10%;
`;

const ArrowUI = styled(ArrowUpLogo)`
width: 50%;
  transform: rotateX(${({ $expended }) => $expended ? "-180" : "0"}deg);
  transition: transform 0.3s ease-in-out;
`;

const SecondButtonSection = styled.div`
  position: relative;
  z-index: 1000000;
  box-shadow: 2px;
  width: 100%;
  gap: 1px;
  display: ${({ $expended }) => $expended ? "flex" : "flex"};
  overflow: hidden !important;
  height: ${({ $expended }) => $expended ? "auto" : "0"};
  opacity: ${({ $expended }) => $expended ? "1" : "0"};
  transition: height .5s ease-in-out, opacity .5s ease-in-out;
  align-items: flex-start;
  flex-direction: column;
`;

const DownloadButtonsContainerUI = styled.div`
  display: flex;
  width: 100%;
  gap: 1px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

const Logo = styled.img`
  height: 31px;
  svg {
    fill: white;

    stroke: white;
    color: white;
  }
`;

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [githubSource, setGithubSource] = useState(basics);
  const [expended, setExpended] = useState(false);

  const handleExpandedChange = useCallback(() => {
    setExpended((prev) => !prev);
  }, []);

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  useEffect(() => {
    setTimeout(
      (async () => {
        const response = await fetch('https://api.github.com/repos/d4data-official/d4data-app/releases/latest');
        const json = await response.json();
        setGithubSource({
          macos: {
            ...basics.macos,
            url: json.assets.find(asset => /D4Data-App-[0-9\.]+\.dmg/.test(asset.name)).browser_download_url,
          },
          windows: {
            ...basics.windows,
            url: json.assets.find(asset => /D4Data-App-Setup-[0-9\.]+\.exe/.test(asset.name)).browser_download_url,
          },
          linux: {
            ...basics.linux,
            url: json.assets.find(asset => /D4Data-App-[0-9\.]+\.AppImage/.test(asset.name)).browser_download_url,
          },
        });
      }), 3000);

  }, [])

  const githubSourceUI = useMemo(() => {
    switch (osName) {
      case 'Mac OS':
        return ({
          main: githubSource.macos,
          secondary: [githubSource.windows, githubSource.linux],
        })
      case 'Windows':
        return ({
          main: githubSource.windows,
          secondary: [githubSource.macos, githubSource.linux],
        })
      default:
        return ({
          main: githubSource.linux,
          secondary: [githubSource.macos, githubSource.windows],
        })
    }
  }, [githubSource])

  const handleClick = useCallback((url) => () => {
    window.open(url, '_blank');
  }, [])

  const onMousAway = useCallback(() => {
    setExpended(false);
  }, [])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Prenez le contrôle sur vos &nbsp; <span className="text-color-primary">données personnelles</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Téléchargez et consultez simplement les données personnelles que les géants du web collectent sur vous.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <DownloadButtonsContainerUI onMouseLeave={onMousAway}>
                    <DownloadButtonContainerUI>
                      <DownloadButtonUI onClick={handleClick(githubSourceUI.main.url)} >
                        <githubSourceUI.main.logo />
                        <span>Téléchargez pour<br />{githubSourceUI.main.osname}</span>
                      </DownloadButtonUI>
                      <ExpendButtonUI onClick={handleExpandedChange}><ArrowUI $expended={expended} /></ExpendButtonUI>
                    </DownloadButtonContainerUI>

                    <SecondButtonSection $expended={expended}>
                      {githubSourceUI.secondary.map(({ url, logo: OsLogo, osname }, index) => {
                        return (
                          <DownloadButtonContainerUI $secondary key={index}>
                            <DownloadButtonUI $secondary onClick={handleClick(url)}>
                              <OsLogo />
                              <span>Téléchargez pour<br />{osname}</span>
                            </DownloadButtonUI>
                          </DownloadButtonContainerUI>
                        )
                      })}
                    </SecondButtonSection>

                  </DownloadButtonsContainerUI>
                  <SourceButtonUI onClick={handleClick("https://github.com/d4data-official/d4data-app")}>
                    <Logo src={GithubLogo} />
                    <span>
                      Voir les sources sur Github
                    </span>
                  </SourceButtonUI>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
