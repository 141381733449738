import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/d4data-logo-white.svg';
import styled from 'styled-components';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const LogoUI = styled(Logo)`
  fill: #333333;
  cursor: pointer;
  &:hover {
    fill: #000000;
  }
`;

const HeaderContainerUI = styled.header`
  width: 100vw;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 651px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const NavigationUI = styled.nav`
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 651px) {
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
  }
`;

const LinkUI = styled(Link)`
  padding: 5px 10px;
  color: #ffffff;
  background-color: #25282C;
  cursor: pointer;
  width: 100%;
  text-align: center;
`;

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }

  const location = useLocation();

  const isAboutPage = useMemo(() => {
    return location.pathname.match(/about/);
  }, [location])

    return (<HeaderContainerUI className="container">
      <LogoUI onClick={() => { window.location.replace('/'); }} />
        <NavigationUI>
          <LinkUI target="_blank" rel="noopener noreferrer"  to={{ pathname: `https://docs.d4data.org` }} >Documentation</LinkUI>
          {!isAboutPage && <LinkUI rel="noopener noreferrer"  to={{ pathname: `/about` }} >À propos</LinkUI>}
        </NavigationUI>
    </HeaderContainerUI>)
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
